<template>
    <div class="overflow-hidden tracking-tight bg-slate-50">
        <div
            class="fixed flex w-full h-screen z-40 md:hidden transition-transform duration-500 ease-in-out"
            :class="[
                mobileMenuOpen
                    ? 'translate-x-0'
                    : '-translate-x-full pointer-events-none select-none'
            ]"
        >
            <AppMenu />
        </div>

        <div class="flex overflow-hidden h-screen">
            <div class="hidden md:flex md:flex-shrink-0">
                <div class="flex flex-col">
                    <AppMenu />
                </div>
            </div>
            <div
                class="flex-1 overflow-auto relative"
                style="scrollbar-gutter: stable"
            >
                <main
                    class="flex-1 layout-base relative overflow-visible h-screen"
                >
                    <slot />
                </main>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useMenuStore from '~/store/menu'
import useSessionStore from '~/store/session'
import * as Sentry from '@sentry/browser'
import AppMenu from '~/components/UI/menu/AppMenu.vue'

const menuStore = useMenuStore()
const sessionStore = useSessionStore()

const mobileMenuOpen = computed(() => menuStore.mobileMenuOpen)

onMounted(() => {
    nextTick(() => {
        // @ts-expect-error - FrontChat is not defined in the window object
        window?.FrontChat?.('init', {
            chatId: '03c4485d687f078c1f56a074c43c9766',
            useDefaultLauncher: true
        })
    })

    if (useRuntimeConfig().public.ENABLE_JIMO) {
        try {
            const windowHDLD = window as any

            if (!windowHDLD.jimo) {
                windowHDLD.jimo = []

                const s = document.createElement('script')

                s.type = 'text/javascript'
                s.async = true
                s.src = 'https://undercity.usejimo.com/jimo-invader.js'

                // Clé en dur car elle ne sert pour l'instant qu'à des tests pour l'équipe produit
                windowHDLD.JIMO_PROJECT_ID =
                    'c813fc1c-63ca-4046-a340-9e070bce3423'
                document.getElementsByTagName('head')[0]?.appendChild(s)

                windowHDLD.jimo.push([
                    'set',
                    'user:email',
                    [sessionStore.user?.email]
                ])
                windowHDLD.jimo.push([
                    'do',
                    'identify',
                    [
                        sessionStore.user?.id.toString(),
                        () => {
                            windowHDLD.jimo.push([
                                'set',
                                'user:name',
                                [sessionStore.user?.fullName]
                            ])
                        }
                    ]
                ])

                windowHDLD.jimo.push([
                    'set',
                    'user:attributes',
                    [{ foo: 'hello', bar: 'world' }, true]
                ])
            }
        } catch {
            Sentry.captureMessage('Jimo error')
        }
    }
})
</script>
